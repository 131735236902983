import React from "react"
import "../../mystyles.scss"

const boucle1 = [
  {
    name: "Marc-Antoine Levionnois",
    temps: "20:07",
  },
  { name: "Christophe Heckel", temps: "21:42" },
  { name: "Weldu", temps: "22:12" },
  { name: "Igor Francey", temps: "22:41" },
  { name: "Tiffany Stauffer", temps: "25:27" },
]

const boucle2 = [
  {
    name: "Marc-Adrien Coen",
    temps: "22:30",
  },
  {
    name: "Céline Guillaume",
    temps: "24:08",
  },
  {
    name: "Florence Francey",
    temps: "25:40",
  },
  {
    name: "Albane Cusin",
    temps: "26:08",
  },
]

const boucle3 = [
  {
    name: "Mélanie Pereira",
    temps: "18:52",
  },
  {
    name: "Julie Brodard",
    temps: "22:44",
  },
]

export default function Resultat() {
  return (
    <div className="container">
      <h1 className="title">Résultats du 01 décembre 2021 : 3-4-5Km</h1>
      <div className="columns">
        <div className="column">
          <h2 className="subtitle"> 5km </h2>
          <table class="table">
            <thead>
              <tr>
                <th>Nom et Prénom</th>
                <th>Temps</th>
              </tr>
            </thead>
            <tbody>
              {boucle1.map(({ name, temps }) => (
                <tr>
                  <td>{name}</td>
                  <td>{temps}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="column">
          <h2 className="subtitle"> 4km </h2>
          <table class="table">
            <thead>
              <tr>
                <th>Nom et Prénom</th>
                <th>Temps</th>
              </tr>
            </thead>
            <tbody>
              {boucle2.map(({ name, temps }) => (
                <tr>
                  <td>{name}</td>
                  <td>{temps}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="column">
          <h2 className="subtitle"> 3km </h2>
          <table class="table">
            <thead>
              <tr>
                <th>Nom et Prénom</th>
                <th>Temps</th>
              </tr>
            </thead>
            <tbody>
              {boucle3.map(({ name, temps }) => (
                <tr>
                  <td>{name}</td>
                  <td>{temps}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
